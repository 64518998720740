import { GRADIANT_WORKOS_IRIS_COLOR_NAME } from "styles/color-consts";
import { LG, XL, XS } from "constants/sizes";
import { starsClusters } from "segments/desktop/core-components/square-tags/default-props";
const defaultLine = {
    top: 270,
    startLeft: 630,
    endLeft: 735
};
export default {
    fullParagraph: {
        title: "A platform built for a new way of working",
        body: "What would you like to manage with monday.com Work OS?"
    },
    subTitle: {},
    subTitleAdvancedConfig: {
        titleSize: XS
    },
    clusters: starsClusters,
    lines: [
        {
            start: {
                top: `${defaultLine.top}`,
                left: `${defaultLine.startLeft}`
            },
            end: {
                top: `${defaultLine.top}`,
                left: `${defaultLine.endLeft}`
            }
        }
    ],
    buttonConfig: {
        buttonColor: GRADIANT_WORKOS_IRIS_COLOR_NAME
    },
    fullParagraphAdvancedConfig: {
        titleSize: XL,
        textSize: LG,
        titleWidth: "800px",
        spaceFromParagraph: "32px",
        buttonTopMargin: "196px"
    },
    paddingTop: "64px"
};
