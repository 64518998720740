export const stars = [
    // top left
    {
        top: 160,
        left: -129
    },
    {
        top: 227,
        left: -106
    },
    {
        top: 20,
        left: -20
    },
    {
        top: 128,
        left: 220
    },
    {
        top: 73,
        left: 315
    },
    {
        top: 218,
        left: 12
    },
    // middle
    {
        top: 50,
        left: 510
    },
    {
        top: 35,
        left: 855
    },
    {
        top: 449,
        left: 424
    },
    {
        top: 515,
        left: 387
    },
    // near signup
    {
        top: 495,
        left: 946
    },
    {
        top: 487,
        left: 985
    },
    {
        top: 480,
        left: 976
    },
    // right
    {
        top: 24,
        left: 1036
    },
    {
        top: 59,
        left: 1138
    },
    {
        top: 86,
        left: 1087
    },
    {
        top: 380,
        left: 1378
    },
    {
        top: 202,
        left: 1522
    }
];
